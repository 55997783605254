import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"white shadow1 rounded",attrs:{"fluid":""}},[_c(VForm,{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return _vm.getProductLedger.apply(null, arguments)}}},[_c(VRow,{staticClass:"pb-4",attrs:{"dense":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto","md":"4"}},[_c(VRow,{staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"3"}},[_vm._v("Product")]),_c(VCol,{attrs:{"cols":"9"}},[_c(VCombobox,{attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.$store.getters['product/products'],"loading":_vm.$store.getters['product/loadingProducts'],"item-text":"display_text","item-value":"id"},on:{"focus":function($event){return _vm.$store.dispatch('product/getProducts')}},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1)],1)],1),_c(VCol,{attrs:{"cols":"auto","md":"5"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,{staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v("Date From")]),_c(VCol,{attrs:{"cols":"8"}},[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}},'v-text-field',attrs,false),on),[_c(VIcon,{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c(VDatePicker,{model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}})],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,{staticClass:"pl-3",staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v("Date To")]),_c(VCol,{attrs:{"cols":"8"}},[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}},'v-text-field',attrs,false),on),[_c(VIcon,{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c(VDatePicker,{model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}})],1)],1)],1)],1)],1)],1),_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"auto","md":"1"}},[_c(VBtn,{staticClass:"text_bg_fave",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v("Search")])],1)],1),_c(VDivider),(_vm.show)?_c(VRow,[_c(VCol,{staticClass:"d_table",attrs:{"cols":"12","id":"printContent"}},[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Rate")]),_c('th',[_vm._v("Stock In")]),_c('th',[_vm._v("Stock Out")]),_c('th',[_vm._v("Stock")])])]),_c('tbody',[_c('tr',[_c('td'),_c('td',[_c('strong',[_vm._v("Opening Stock")])]),_c('td',{attrs:{"colspan":"3"}}),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters['product/openingStock']))])])]),_vm._l((_vm.$store.getters['product/ledgers']),function(product,ind){return _c('tr',{key:ind},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(product.date))]),_c('td',[_vm._v(_vm._s(product.description))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(product.rate))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(product.in_quantity))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(product.out_quantity))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(product.stock))])])})],2)]},proxy:true}],null,false,2329120206)})],1)],1):_c('div',{staticClass:"no_result"},[_c('div',{staticClass:"img"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }